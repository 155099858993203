import {
  DictionaryType,
  IDictionary,
  sdkGetDictionaries,
  sdkGetUserDashboard,
} from '@unione-pro/unione.assmnt.sdk.webapp';
import { IMultiSelectOption } from '@unione-pro/unione.assmnt.ui-kit.webapp';
import { makeAutoObservable, onBecomeObserved, runInAction } from 'mobx';
import { DashboardMappers } from '../mappers/dashboard.mappers';
import { ICourseStageDashboard, IDashboardStore } from '../models/stores/dashboard.store';
import { IRootStore } from '../models/stores/root.store';
import { getErrorMessage } from '../shared/error-message';

export class DashboardStore implements IDashboardStore {

  public readonly rootStore: IRootStore;

  public data: ICourseStageDashboard[] | null = null;
  public dictionaries?: IDictionary[] = undefined;
  public loading: boolean = false;
  public error: string | undefined = undefined;

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this, {
      rootStore: false,
    });
    onBecomeObserved(this, 'dictionaryOptions', this.getDictionaries);
  }

  public get = async(courseId: string): Promise<void> => {
    try {
      runInAction(() => {
        this.data = null;
        this.loading = true;
        this.error = undefined;
      });

      const dashboard = await sdkGetUserDashboard({
        baseURL: this.rootStore.config.builderAPI,
        token: this.rootStore.auth.token,
        data: {
          id: courseId,
        },
      });

      runInAction(() => {
        this.data = DashboardMappers.getDashboard({
          dashboard,
          model: this.rootStore.model.data,
        });
        this.loading = false;
      });
    }
    catch (error) {
      runInAction(() => {
        this.loading = false;
        this.error = getErrorMessage(error);
      });
    }
  };

  public getDictionaries = async(): Promise<void> => {
    if (this.dictionaries) {
      return;
    }

    try {
      const industryReq = sdkGetDictionaries({
        baseURL: this.rootStore.config.builderAPI,
        data: { type: DictionaryType.industry },
        token: this.rootStore.auth.token,
      });

      const industry24Req = sdkGetDictionaries({
        baseURL: this.rootStore.config.builderAPI,
        data: { type: DictionaryType.industry24 },
        token: this.rootStore.auth.token,
      });

      const response = await Promise.all([industryReq, industry24Req]);

      runInAction(() => {
        this.dictionaries = DashboardMappers.getAllIndustryList(response);
      });
    }
    catch (error) {
      runInAction(() => {
        this.error = getErrorMessage(error);
      });
    }
  };

  public get dictionaryOptions(): IMultiSelectOption[] {
    if (!this.dictionaries) {
      return [];
    }

    const options: IMultiSelectOption[] = this.dictionaries.map((dictionary) => ({
      label: dictionary.value,
      value: dictionary.id,
    }));

    return options;
  }

  public reset(): void {
    this.data = null;
  }

}
